


'use client'
import { useCallback } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async'
import './UiSearchSelect.sass';

interface Props {
  onChange: (e: any) => void;
  value: any;
  getOptions: (value?: any) => void;
  options: any;
  className?: string;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export interface ISearchData {
  value: any;
  label: string;
  [key: string]: any;
}

const UiSearchSelect: React.FC<Props> = ({
  onChange,
  value,
  getOptions,
  options,
  className = '',
  isMulti,
  label,
  placeholder,
  error,
  disabled,
  required,
}) => {
  const loadOptions = useCallback(
    debounce(async (inputValue, callback) => {
      const res = (await getOptions(inputValue)) as any;
      return callback(res || options);
    }, 500),
    [getOptions]
  );

  return (
    <div
      className={cn('ui-search-select', {
        [className]: className,
        'ui-search-select--error': error,
      })}
    >
      {/* {label && (
        <div className='ui-search-select__label'>
          {label} {required && '*'}
        </div>
      )} */}
      <AsyncSelect
        placeholder={placeholder ? placeholder : label}
        loadOptions={loadOptions}
        value={value || ''}
        onChange={onChange}
        // onInputChange={(value, { action }) => checkEmptyValue(value, action)}
        isDisabled={disabled}
        isMulti={isMulti ? true : false}
        defaultOptions={options}
        options={options}
        className='ui-search-select__container'
        classNamePrefix='ui-search-select'
      />
    </div>
  );
};

export default UiSearchSelect;
