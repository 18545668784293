import instance from 'api/instance';
import {
  ICreateApplication,
  IApplicationByUser,
  IGetApplications,
  IGetApplicationById,
  IUpdateApplication,
  IChnageVerifyStatus,
  IApplicationPass,
  IPassBinding,
  IUpdateApplicationByUser,
  IApplicationByPass,
  IApplicationGaiVerrify,
  IUpdateApplicationStatus,
  IVerificationApplication,
  IForceVerify,
  IUpdateForeigner,
  IUpdateApplicationLang,
  IGetApplicationsFileBySchool,
} from './types';
import qs from 'query-string';

export const getApplications: IGetApplications = (params) =>
  instance.get(
    `public/applications?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getApplicationByPass: IApplicationByPass = (pass) =>
  instance.get(`/applications/pass/${pass}`);

export const getApplicationByCode = (code: string) =>
  instance.get(`/applications/code/${code}`);

export const getApplicationById: IGetApplicationById = (id) =>
  instance.get(`public/applications/${id}`);

export const createApplication: ICreateApplication = (data) =>
  instance.post(`/applications`, data);

export const updateApplication: IUpdateApplication = (id, data) =>
  instance.put(`/applications/${id}`, data);

export const applicationGaiVerrify: IApplicationGaiVerrify = (id) =>
  instance.post(`/public/applications/${id}/gai-verify`);

export const updateApplicationStatus: IUpdateApplicationStatus = (id, params) =>
  instance.patch(`/applications/${id}/status`, params);

export const updateApplicationByUser: IUpdateApplicationByUser = (id, data) =>
  instance.put(`public/applications/${id}/me`, data);

export const createApplicationByUser: IApplicationByUser = (data) =>
  instance.post(`public/applications/user`, data);

export const applicationPass: IApplicationPass = (data) =>
  instance.put('/applications/connect', data);

export const rejectApplication = (id: string) =>
  instance.post(`/status-history/application/${id}/reject`);

export const verifyApplication = (id: string) =>
  instance.post(`/status-history/application/${id}/verify`);

export const passBinding: IPassBinding = (data) =>
  instance.post('/applications/pass', data);

export const updateVisitDate = (id: number, data: { date: string }) =>
  instance.patch(`public/applications/${id}/date`, data);

export const verificationApplication: IVerificationApplication = (id, data) =>
  instance.put(`/applications/${id}/verify`, data);

export const forceVerify: IForceVerify = (id, data) =>
  instance.put(`/applications/${id}/verify/true`, data);

export const updateForeigner: IUpdateForeigner = (id, data) => instance.patch(`/applications/${id}/foreigner`, data)

export const updateApplicationLang: IUpdateApplicationLang = (id, data) => instance.patch(`/applications/${id}/lang`, data)

export const getApplicationFileBySchool: IGetApplicationsFileBySchool = (params) =>
  instance.get(
    `/applications/export?${qs.stringify(params, { skipEmptyString: true })}`
  );