const USER_ROLES = [
	{
		value: 'SU',
		label: 'SU',
	},
	{
		value: 'CASHIER',
		label: 'CASHIER',
	},
	{
		value: 'RECEPTION',
		label: 'RECEPTION',
	},
	{
		value: 'USER',
		label: 'USER',
	},
	{
		value: 'MOTORDROME',
		label: 'MOTORDROME',
	},
	{
		value: 'EXAM',
		label: 'EXAM',
	},
	{
		value: 'SECURITY',
		label: 'SECURITY',
	},
	{
		value: 'QUEUE',
		label: 'QUEUE',
	},
	{
		value: 'QUEUE_MANAGER',
		label: 'QUEUE_MANAGER',
	},
	{
		value: 'GAI',
		label: 'GAI'
	},
	{
		value: 'HEAD_ACCOUNTANT',
		label: 'HEAD_ACCOUNTANT'
	},
	{
		value: 'ACCOUNTANT',
		label: 'ACCOUNTANT'
	},
	{
		value: 'UMCH',
		label: 'UMCH'
	},
	{
		value: 'REPORT',
		label: 'REPORT'
	},
	{
		value: 'APPEAL',
		label: 'APPEAL'
	},
	{
		value: 'UZAKOVA',
		label: 'UZAKOVA'
	},
	{
		value: 'BRANCH_HEAD',
		label: 'BRANCH_HEAD'
	},
	{
		value: 'ASSISTANT_BRANCH_HEAD',
		label: 'ASSISTANT_BRANCH_HEAD'
	},
	{
		value: 'SATURDAY_EDITOR',
		label: 'SATURDAY_EDITOR'
	},
	{
		value: 'HEAD',
		label: 'HEAD'
	},
	{
		value: 'HEAD_OF_IT',
		label: 'HEAD_OF_IT'
	},
	
];

export default USER_ROLES;
